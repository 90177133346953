<template>
  <header
    ref="siteHeaderEl"
    class="site-header"
    data-background="transparent"
    :class="{ '-nav-open': isNavOpen }"
  >
    <NuxtLink :to="{ name: `index___${locale}` }" class="link">
      <p class="text _visually-hidden">{{ t('homeLink') }}</p>
      <img v-if="logo" class="logo" :src="logo.url" :alt="logo.alt ?? ''" />
    </NuxtLink>
    <MainNavigation :key="locale" :is-open="isNavOpen" />
    <LangSwitch :key="locale" :is-nav-open="isNavOpen" />
    <button
      aria-controls="main-navigation"
      class="toggle _hidden-vl"
      @click="toggleNav()"
    >
      <span class="text text-sm">{{
        isNavOpen ? t('closeNav') : t('openNav')
      }}</span>
      <span class="burger" :class="{ '-active': isNavOpen }">
        <span class="line"></span>
        <span class="line"></span>
      </span>
    </button>
  </header>
</template>

<i18n lang="yaml">
fr:
  openNav: Menu
  closeNav: Fermer
  homeLink: Page d'accueil
de:
  openNav: Menü
  closeNav: Schliessen
  homeLink: Startseite
</i18n>

<script setup lang="ts">
import LangSwitch from '~/components/site-header/LangSwitch.vue';
import MainNavigation from '~/components/site-header/MainNavigation.vue';

const { isVs } = useViewportSize();

const isNavOpen = ref<boolean>(false);
const toggleNav = useToggle(isNavOpen);

const siteHeaderEl = ref<HTMLElement | null>(null);

const route = useRoute();

watch(
  () => route.path,
  function () {
    if (isVs.value) {
      isNavOpen.value = false;
    }
  },
);

const nuxtApp = useNuxtApp();
const { locale, t } = useI18n();

const logo = nuxtApp.$SiteInfo?.logo;

onMounted(function () {
  if (siteHeaderEl.value) {
    nuxtApp.$SiteHeader?.setSiteHeader(siteHeaderEl.value);
  }
});
</script>

<style scoped lang="scss">
.app:not(:has(.horizontal-layout)) .site-header {
  padding: 0.5rem
    calc(var(--site-outer-margin, 0) + var(--base-component-padding-inline));
}

.site-header {
  padding: 0.5rem var(--base-component-padding-inline);
  background-color: transparent;
  z-index: 10;
  position: fixed;
  inset: 0 0 auto 0;
  display: flex;
  gap: 1rem;
  //width: 100%;
  max-width: 100vw;
  align-items: center;
  justify-content: space-between;
  height: var(--site-header-height);

  &[data-background='transparent'] {
    // vs: remove mix blend move when nav open
    @media (--vs) {
      &:not(.-nav-open) {
        color: var(--color-white);
        mix-blend-mode: difference;

        > .link {
          > .logo {
            filter: invert(1);
          }
        }
      }

      &.-nav-open {
        color: var(--color-black);

        > .toggle {
          background-color: var(--color-alabaster-100);
        }
      }
    }

    @media (--vl) {
      color: var(--color-white);
      mix-blend-mode: difference;

      > .link {
        > .logo {
          filter: invert(1);
        }
      }
    }
  }

  &[data-background='opaque'] {
    background: rgba(250, 250, 250, 0.6);
    mix-blend-mode: unset;
    color: var(--color-black);

    &:not(.-nav-open) {
      backdrop-filter: blur(55px);

      :deep(.icon > .image) {
        // unset filter for globe in lang switch
        filter: unset !important;
      }

      > .link {
        > .logo {
          filter: unset !important;
        }
      }
    }
  }

  > .link {
    z-index: 10;
    outline: none;
    border: none;

    > .logo {
      height: 2.5rem;
    }
  }

  > .toggle {
    z-index: 10;
    display: flex;
    cursor: pointer;
    border: hidden;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: transparent;
    color: currentColor;

    > .burger {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;

      &.-active {
        > .line {
          &:nth-child(1) {
            transform: translateY(0) rotate(25deg) scaleX(1.05);
          }

          &:nth-child(2) {
            transform: translateY(0) rotate(-25deg) scaleX(1.05);
          }
        }
      }

      > .line {
        position: absolute;
        width: 2rem;
        height: 1.5px;
        background-color: currentColor;
        transition: transform 250ms ease-out;

        &:nth-child(1) {
          transform: translateY(-0.25rem);
        }

        &:nth-child(2) {
          transform: translateY(0.25rem);
        }
      }
    }
  }
}
</style>
